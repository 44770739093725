import React from 'react'
import Slider from 'react-slick'
import { NextArrow, PrevArrow } from '../Arrow';
import { useNavigate } from 'react-router-dom';

const FirstCard = () => {
    const navigate = useNavigate()
    var settings = {
        dots: false,
        infinite: true,  
        speed: 500,
        arrows:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      };
      const data = [
        {
            name:"भवन का शिलान्यास",
            description:"दिनांक 20/11/1988 को सरदार वल्लभ भाई पटेल स्मारक ट्रस्ट, सतना का निर्माण कर सरदार पटेल स्मारक भवन का शिलान्यास सरदार पटेल की सुपुत्री सुग्री मणिबेन पटेल के कर कमलों द्वारा उनकी पुण्य तिथि 15/12/1988 को केन्द्रीय जेल के पूर्व सतना में ट्रस्ट के स्वामित्व की 1.25 acre भूखण्ड पर किया गया।",
            img_url:"/images/60231Innaugration.jpg",
            link:"/download"
           
        }
    ,
        {   name:"हमारे बारे में",
            description:"सरदार पटेल के बहुआयामी व्यक्तित्व के अनुरूप पांच मंजिला विविध विभाग भवनों का निर्माण कर विभिन्‍न गतिविधियाँ संचालित करते हुए सरदार पटेल के सपनों का समृद्धिशाली, शक्तिशाली, स्वाभिमानी एवं भेदभाव रहित समता मूलक समाज वाले भारत के निर्माण के लिए उनके आदर्शों-विचारों को जन-जन तक पहुंचाना। यथा-पुस्तकालय -वाचनालय, शोधपीठ भवन, प्रकाशन विभाग भवन, अतिथि कक्ष, छात्रावास, चिकित्सालय, प्रशिक्षण संस्थान, वृहद सभागार, कृषि शोध संस्थान, सामुदायिक एवं सांस्कृतिक भवन, आर्ट गैलरी एवं कैंटीन आदि प्रमुख हैं।",
            img_url:"/images/1413ABOUTUS.jpg",
            link:"/about"
        },
        {   name:"भविष्य की कार्य योजनाएं",
        description:"आगामी दो वर्षो (2022 एवं 2023) में छात्रावास, अतिथि कक्ष, पुस्तकालय, वाचनालय, प्रशिक्षण संस्थान एवं कैंटीन संचालित करना। पूर्व पृष्ठ में वार्णित योजनाएं एवं समयानुसार अन्य जो लोक कल्याण की योजनाएं होंगी भविष्य में निरंतर संचालित की जाती रहेंगी। शिक्षा, स्वास्थ्य, कृषि, पर्यावरण, जल संरक्षण-संवर्धन, नशाउन्मूलन, महिला शशक्तिकरण आदि विषयों पर बीस समितियों द्वारा विविध जागरुकता सम्मेलनों-संगोष्ठियों का आयोजन।",
        img_url:"/images/aboutimg.jpg",
        link:"/events"
         },
        
       
    ]
  return (
    <div className='px-5'>
      <Slider {...settings}>

{
            data.map((elem)=>{
                return(
                    <>
            <div className="card-head w-100 px-5">
    <div className="card cr-pointer h-100-per w-100 card-border d-flex ">
     <div className="card-img h-30 p-3 d-flex align-items-center justify-content-center overflow-hidden">
         <img src={`${elem.img_url}`} alt=""className='w-auto'/>
     </div>
     <div className="card-body text-left">
         <div className="card-title fw-bold fw-600 line-height-1-5">
             {elem.name}
         </div>    
         <div className="para h-20">
            {elem.description? elem.description.slice(0,200):'' }...
        </div>    
        <div className="btn btn-sm bg-pink-1 text-white fs-6" onClick={() => navigate(elem.link)}>Read More</div>
        
     </div>
     
     </div>
            </div>

</>
                )

            })
        }

</Slider>

    </div>
  )
}

export default FirstCard