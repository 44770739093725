import { Box, Typography } from "@mui/material";
import React from "react";

const DonateNow = () => {
  return (
    <Box
      class="w-100 p-3 d-flex gap-3"
      style={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" class="color-orange-red">
        Under Maintainance
      </Typography>
    </Box>
  );
};

export default DonateNow;
