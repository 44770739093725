import * as React from "react";
import { ImageList } from "@mui/material";
import { ImageListItem } from "@mui/material";
import { imageList } from "../../../../assets/data";

export default function QuiltedImageList() {
  return (
    <ImageList sx={{ width: 800, overflow: "hidden" }} cols={3}>
      {imageList.map((item) => (
        <ImageListItem key={item.img} sx={{ m: 1, cursor: "pointer" }}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
