import React from 'react'
import aboutbanner from "../../../../assets/banners/aboutbanner.png"

const About = () => {
  return (
    <div>
        <div className="w-100">
            <img src={aboutbanner} alt="" className='h-100-per w-100 ' />
        </div>

       <div className="w-100 p-5 gap-5 d-flex">
       <div className="w-30">
            <img src="/images/aboutimg.jpg" alt="" className='w-100 rounded-3' />
        </div>
        <div className="w-70">
            <h2 className='color-orange-red'>About Us</h2>
            <p>सरदार पटेल के बहुआयामी व्यक्तित्व के अनुरूप पांच मंजिला विविध विभाग भवनों का निर्माण कर विभिन्‍न गतिविधियाँ संचालित करते हुए सरदार पटेल के सपनों का समृद्धिशाली, शक्तिशाली, स्वाभिमानी एवं भेदभाव रहित समता मूलक समाज वाले भारत के निर्माण के लिए उनके आदर्शों-विचारों को जन-जन तक पहुंचाना। यथा-पुस्तकालय -वाचनालय, शोधपीठ भवन, प्रकाशन विभाग भवन, अतिथि कक्ष, छात्रावास, चिकित्सालय, प्रशिक्षण संस्थान, वृहद सभागार, कृषि शोध संस्थान, सामुदायिक एवं सांस्कृतिक भवन, आर्ट गैलरी एवं कैंटीन आदि प्रमुख हैं।

निःशुल्क चिकित्सा शिविर, महापुरुषों की जयंती, पुण्यतिथि समारोह, विचार गोष्ठियाँ, भाषण-माला, देश-समाज के लिये उल्लेखनीय कार्य करने वाले समाज सेवियों को सम्मानित करना, प्रतिभाशाली छात्रों को सम्मान एवं प्रतिभा- प्रोत्साहन पुरस्कार देना, उन्‍नत एवं जैविक कृषि के लिए प्रशिक्षण एवं प्रोत्साहन, उन्‍नतशील किसानों को सम्मान, उच्च प्रशासनिक पदों हेतु प्रशिक्षण, निर्धन छात्रों को उच्च शिक्षा हेतु मार्गदर्शन एवं अन्य सहायता करना । शिक्षा, स्वास्थ्य, विज्ञान, खेलकूद, समाज सेवा एवं अन्य क्षेत्रों में उत्कृष्ट प्रदर्शन करने वाले छात्र-छात्राओं को प्रतिभा प्रोत्साहन पुरुस्कार एवं सम्मान।

भारत के अखण्ड-अनश्वर भूगोल को सुरक्षित बनायें रखने के लिए सौहार्द, सद्भाव विषयक आयोजन करना। सरदार पटेल के सपनों का स्वर्णिम भारत निर्माण करने के लिए लोगों में देश प्रेम की भावना जागृत करते हुए चारित्रिक मूल्यों को विकसित करना।</p>
      
      <p><b className='color-orange-red'>स्वरुप -</b>  ट्रस्ट के संविधन की कंडिका १(खा) के अनुसार इस ट्रस्ट का स्वरुप सामाजिक कल्याण एवं पूर्ती संस्था सोसल एवं चेरिटेबल इंस्टीटूशन के रूप में रहेगा। </p>
      <p><b className='color-orange-red'>80G से मान्यता प्राप्त -</b> इस ट्रस्ट को 80G के तहत आयकर विभाग से आदेश क्रमांक ITB/EXM/S/80-G/2017-18/1008760462  दिनांक 12/02/2018 द्वारा आयकर छूट का प्रमाण पत्र जारी करने की मान्यता प्राप्त है।</p>
      

      <p> <b className='color-orange-red'>अनुदान  - </b> कोई भी अनुदानकर्ता ट्रस्ट के अकाउंट में अनुदान राशि ऑनलाइन जमा करा सकता है।  </p>
      <p> <b className='color-orange-red'>ACCOUNT NUMBER: 10298140181</b></p>
       <p><b className='color-orange-red'>IFSC CODE: SBIN0000474</b></p>

       <p><b className='color-orange-red'>कार्य छेत्र -</b> इस ट्रस्ट का कार्य छेत्र सम्पूर्ण विश्व होगा।  विश्व में सरदार पटेल के नाम स्थापित कोई भी संस्था , ट्रस्ट या संगठन को और इसकी सम्पूर्ण परिसम्पत्तियों (चल/अचल) को अगर वे संथाये चाहे तो इससे सम्बद्ध किया जा सकेगा। </p>
       <b className='color-orange-red'>शिलान्यास</b>
       <p>दिनांक 20/11/1988 को सरदार वल्लभ भाई पटेल स्मारक ट्रस्ट, सतना का निर्माण कर सरदार पटेल स्मारक भवन का शिलान्यास सरदार पटेल की सुपुत्री सुश्री मणिबेन पटेल के कर कमलों द्वारा उनकी  पुण्य तिथि 15/12/1988 को केन्द्रीय जेल के पूर्व सतना में ट्रस्ट के स्वामित्व की 1.25 एकड़ भूखण्ड पर किया गया।</p>
       
       <b className='color-orange-red'>भविष्य की कार्य योजनाएं - </b>

       <p>आगामी दो वर्षो (2022 एवं 2023) में छात्रावास, अतिथि कक्ष, पुस्तकालय, वाचनालय, प्रशिक्षण
संस्थान एवं कैंटीन संचालित करना। पूर्व पृष्ठ में वार्णित योजनाएं एवं समयानुसार अन्य जो लोक कल्याण की योजनाएं होंगी भविष्य में निरंतर संचालित की जाती रहेंगी। शिक्षा, स्वास्थ्य, प्रतिभा प्रोत्साहन , महा पुरुष सम्मान , सांस्कृतिक एवं शिलालेख , पुस्तकालय एवं वाचनालय , वित्त प्रबंधन , योजना , कृषि विकास , पर्यावरण, जल संरक्षण-संवर्धन, नशाउन्मूलन, महिला शशक्तिकरण आदि विषयों पर बीस समितियों द्वारा विविध जागरुकता सम्मेलनों-संगोष्ठियों का आयोजन।</p>
       
        </div>
       </div>


    </div>
  )
}

export default About