import React from "react";
import { NavLink } from "react-router-dom";
import telephone from "../../../../assets/icons/telephone.png";
import email from "../../../../assets/icons/message.png";
import loc from "../../../../assets/icons/pin.png";

const Footer = () => {
  return (
    <div className="w-100 mt-5 ">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 fs-6 d-flex justify-content-center">
            <div className="lh-lg">
              <NavLink className="text-decoration-none text-dark">
                <div>Home</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>About Us</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Founder Member</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Current Member</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Upcoming Events</div>
              </NavLink>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12 fs-6 d-flex justify-content-center">
            <div className="lh-lg">
              <NavLink className="text-decoration-none text-dark">
                <div>Complete Events</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Download</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Photo Gallery</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Video Gallery</div>
              </NavLink>
              <NavLink className="text-decoration-none text-dark">
                <div>Contact Us</div>
              </NavLink>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12 fs-6">
            <div className="row">
              <div className="col-1">
                <img src={loc} alt="" className="w-m-2" />
              </div>
              <div className="col-11">
                OFFICE: Near Central Jail , Behind Medical College Satna (M.P.)
                485001
              </div>
            </div>
            <div className="row my-5">
              <div className="col-1">
                <img src={loc} alt="" className="w-m-2" />
              </div>
              <div className="col-11 ">
                CORRESPONDENCE ADDRESS: Gali No:2, Friends Colony, Satna (M.P.)
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12 fs-6">
            <div className="row">
              <div className="col-1">
                <img src={telephone} alt="" className="w-m-2" />
              </div>
              <div className="col-11">
                <p style={{ marginBottom: 0 }}>ट्रस्ट : 9522202022</p>
                <p style={{ marginBottom: 0 }}>
                  {" "}
                  गेंदलाल भाई पटेल सचिव :7000923223
                </p>
                {/* 09981152270, 09179059653, 09109623784, 9893395422
                    6264587037 */}
              </div>
            </div>
            <div className="row my-5">
              <div className="col-1">
                <img src={email} alt="" className="w-m-2" />
              </div>
              <div className="col-11 ">Svbptrust123@gmail.com </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center bg-pink text-white p-3 fs-5">
        {" "}
        © SARDAR VALLABH BHAI PATEL SMARAK TRUST SATNA MP || Amitesh Singh
        8602632652
      </div>
    </div>
  );
};

export default Footer;
