import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { pastEvents } from "../../../../assets/data";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "",
    headerName: "Photo",
    width: 250,
    renderCell: (params) => (
      <>
        <Box sx={{ width: "100%", display: "flex", gap: 1 }}>
          <img
            style={{ height: "100%", width: "50%" }}
            src={params?.row?.img1}
          />
          <img
            style={{ height: "100%", width: "50%" }}
            src={params?.row?.img2}
          />
        </Box>
      </>
    ),
  },

  {
    field: "title",
    headerName: "Title",
    width: 250,
  },
  {
    field: "date",
    headerName: "Date",
    width: 150,
  },
  {
    field: "description",
    headerName: "Description",
    width: 750,
  },
];

export default function Table({ data }) {
  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        sx={{
          color: "#807474",
          fontWeight: 600,
          borderColor: "#adaaaa",
          "& .MuiDataGrid-columnHeaders": {
            fontWeight: 600,
            borderRadius: "var(--none, 0px)",
            borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
            borderLeft:
              "var(--none, 0px) solid var(--divider, rgba(0, 0, 0, 0.12))",
            borderRight:
              "var(--none, 0px) solid var(--divider, rgba(0, 0, 0, 0.12))",
            borderTop:
              "var(--none, 0px) solid var(--divider, rgba(0, 0, 0, 0.12))",
            background: "var(--primary-selected, rgb(255,4,112))",
            alignItems: "space-between !important",
          },
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableCheckboxSelection
        disableRowSelectionOnClick
        rowHeight={80}
      />
    </Box>
  );
}
