import React from "react";
import QuiltedImageList from "../../helper/ImageList/QuiltedImageList";
import { Box } from "@mui/material";

const Gallery = () => {
  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "center", p: 3 }}
    >
      <QuiltedImageList />
    </Box>
  );
};

export default Gallery;
