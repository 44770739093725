import React, { useState } from 'react'
import profile from "../../../../assets/icons/profile.png"
import twitter from "../../../../assets/icons/twitter.png"
import facebook from "../../../../assets/icons/facebook.png"
import signup from "../../../../assets/icons/add-friend.png"
import logout from "../../../../assets/icons/shutdown.png"





import search from "../../../../assets/icons/magnifying-glass.png"
import { NavLink, useNavigate } from 'react-router-dom'


const TopHead = () => {
    const navigate =useNavigate()
    return (
        <>
         <div class="top_nav d-flex align-items-center justify-content-around color-grey fw-500 h-3 w-100" disabled="" style={{opacity: 0.5, cursor: "text"}}>
         <div class="left_top_nav d-flex align-items-center gap-3 "><select name="language" id="" class="fs-ss-9 color-grey fw-500 bg-transparent border-0" disabled=""><option value="">english</option><option value="">hindi</option></select></div>



          <div className="left_top_nav d-flex align-items-center gap-3">
          <div className="profile d-flex align-items-center gap-3 justify-content-center fs-ss-9">
                <img src={profile} alt="" className='w-m-2'/>
                Amit
            </div>
            <div className="profile d-flex align-items-center gap-3 justify-content-center fs-ss-9">
                <img src={facebook} alt="" className='w-m-2'/>
            </div>
          
            <div className="profile d-flex align-items-center gap-3 justify-content-center fs-ss-9 cr-pointer">
                <img src={twitter} alt="" className='w-m-2'/>
            </div>

            <div className="search d-flex flex-align-center cr-pointer">
            <img src={search} alt="" className='w-m-2' />              
             </div>
             <div className="search d-flex flex-align-center cr-pointer">
            <img src={signup} alt="" className='w-m-2' />              
             </div>
             <div className="search d-flex flex-align-center cr-pointer">
            <img src={logout} alt="" className='w-m-2' />              
             </div>
        </div>



    </div>
         
        </>
  )
}

export default TopHead