import React from 'react'
import mon1 from "../../../assets/icons/mon1.png"
import mon2 from "../../../assets/icons/mon2.png"
import mon3 from "../../../assets/icons/mon3.png"
import mon4 from "../../../assets/icons/mon4.png"

const DonateBanner = () => {
  return (
    <>
     <div className="h-30 p-3">
            <div className="banner h-100-per w-100 bg-pink rounded-3 d-flex overflow-hidden p-3">
                <div className="w-70 px-3 d-flex flex-column justify-content-center">
                    <h2 className='color-white'>Sardar Vallab Bhai Patel Trust</h2>
                    <div className="bg-white rounded-1 color-orange-red fw-bold fs-5 btn w-fit"> Donate Now</div>

                </div>
                <div className="w-30 h-100 d-flex align-items-center justify-content-center position-relative">
                    <img src={mon1} alt=""  className='w-3 '  style={{marginTop:"-50px"}}/>
                    <img src={mon3} alt="" className='w-7'/>
                    <img src={mon4} alt="" className='w-3' style={{marginTop:"50px"}}/>


                </div>


            </div>

        </div>
    </>
  )
}

export default DonateBanner