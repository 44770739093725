import { Box } from "@mui/material";
import React from "react";

const ConactUs = () => {
  return (
    <Box class="w-100 p-3 d-flex gap-3">
      <Box
        class="bg-pink-1"
        style={{
          maxWidth: "500px",
          minWidth: "500px",
          height: "250px",
          borderRadius: "8px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <h4 style={{ color: "white" }}>Contact Card -</h4>
        <div class="row gap-2 align-items-center">
          <div class="col-1">
            <Box
              class="d-flex align-items-center justify-content-center"
              style={{
                background: "white",
                height: "40px",
                width: "40px",
                borderRadius: "5px",
              }}
            >
              {" "}
              <img
                src="/static/media/telephone.2df4e0d3f5b0432e9460.png"
                alt=""
                class="w-m-2"
              />
            </Box>
          </div>
          <div
            class="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
          >
            <p style={{ marginBottom: 0 }}>ट्रस्ट : 9522202022</p>
            <p style={{ marginBottom: 0 }}>
              {" "}
              गेंदलाल भाई पटेल सचिव :7000923223
            </p>
            {/* 09981152270, 09179059653, 09109623784, 9893395422, 6264587037, */}
          </div>
        </div>
        <div class="row gap-2 align-items-center">
          <div class="col-1">
            <div
              class="d-flex align-items-center justify-content-center"
              style={{
                background: "white",
                height: "40px",
                width: "40px",
                borderRadius: "5px",
              }}
            >
              {" "}
              <img
                src="/static/media/message.62e69187989ff15060c8.png"
                alt=""
                class="w-m-2"
              />
            </div>
          </div>
          <div
            class="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
          >
            Svbptrust123@gmail.com
          </div>
        </div>
      </Box>
      <Box
        class="bg-pink-1"
        style={{
          maxWidth: "500px",
          minWidth: "500px",
          height: "250px",
          borderRadius: "8px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <h4 style={{ color: "white" }}>Address Card -</h4>
        <div class="row gap-2 align-items-center">
          <div class="col-1">
            <div
              class="d-flex align-items-center justify-content-center"
              style={{
                background: "white",
                height: "40px",
                width: "40px",
                borderRadius: "5px",
              }}
            >
              {" "}
              <img
                src="/static/media/pin.c4670350c2ac7c63718e.png"
                alt=""
                class="w-m-2"
              />
            </div>
          </div>
          <div
            class="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
          >
            OFFICE: Near Central Jail , Behind Medical College Satna (M.P.)
            485001
          </div>
        </div>
        <div class="row gap-2 align-items-center">
          <div class="col-1">
            <div
              class="d-flex align-items-center justify-content-center"
              style={{
                background: "white",
                height: "40px",
                width: "40px",
                borderRadius: "5px",
              }}
            >
              {" "}
              <img
                src="/static/media/pin.c4670350c2ac7c63718e.png"
                alt=""
                class="w-m-2"
              />
            </div>
          </div>
          <div
            class="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
          >
            CORRESPONDENCE ADDRESS: Gali No:2, Friends Colony, Satna (M.P.)
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ConactUs;
