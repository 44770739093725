import React, { useState } from "react";
import founder from "../../../../assets/icons/teamwork.png";
import current from "../../../../assets/icons/diversity.png";
import right from "../../../../assets/icons/right.png";

import vidobanner from "../../../../assets/banners/vidobanner.mp4";

import MemberCardNoSlider from "../../helper/Cards/MemberCardNoSlider";
import DonateBanner from "../../helper/DonateBanner";
import { currentMember, foundeMember } from "../../../../assets/data";

const Member = () => {
  const [state, setState] = useState(1);
  return (
    <div className="w-100">
      <DonateBanner />

      <div className="d-flex w-100 shadow-theme-1 fw-bold text-center fs-5">
        <div
          className={`w-50 ${
            state === 1 ? " bg-pink color-white" : "color-orange-red"
          }  p-2  d-flex gap-3 align-items-center justify-content-center cr-pointer`}
          onClick={() => setState(1)}
        >
          <img src={founder} alt="" className="w-2" /> Founder Member
        </div>
        <div
          className={`w-50 ${
            state === 2 ? " bg-pink  color-white" : "color-orange-red"
          }  p-2  d-flex gap-3 align-items-center justify-content-center cr-pointer`}
          onClick={() => setState(2)}
        >
          <img src={current} alt="" className="w-2" /> Current Member
        </div>
      </div>

      <div className="w-100 d-flex gap-3 p-3">
        <div className="w-25 rounded-3 p-3 bg-light-orange-redd">
          <h5 className="color-orange-red fw-bold">संस्थापक सदस्य थे</h5>

          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> सर्व श्री महेन्द्र सिंह,
            (नेता जी) अध्यक्ष, ग्राम- पोस्ट दलदल, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> छोटेलाल सिंह, उपाध्यक्ष,
            ग्राम-हरदासपुर, पोस्ट सरला नगर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> प्रो. आर. डी. सिंह, सचिव,
            ग्राम पोस्ट भमरा, जिला-रीवा (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> रामनिरंजन सिंह ( पूर्व
            प्राचार्य), कोषाध्यक्ष, ग्राम पोस्ट खम्हरिया, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> चौधरी महेन्द्र सिंह, सह
            सचिव, ग्राम रघुनाथपुर, पोस्ट जर्नादनपुर, जिला सतना (म.प्र.)
          </h6>

          <h5 className="color-orange-red fw-bold">कार्यकारणी सदस्य</h5>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> तोषण सिंह ( पूर्व विधायक)
            ग्राम- पोस्ट दलदल, जिला सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> लाल जी पटेल ( पूर्व
            विधायक) मैहर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> बैजनाथ प्र. (दादू पटेल )
            नदीपार कटनी, जिला-कटनी (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> रामाधार सिंह ( मुंशी जी)
            ग्राम मढ़ा, पोस्ट बेला, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> रामकरण सिंह (पूर्व
            प्राचार्य), ग्राम देऊ, पोस्ट महुडर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> गोविन्द सिंह (पूर्व सरपंच
            ), ग्राम-पोस्ट खोहर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> शारदा सिंह, ग्राम- पोस्ट
            कोटर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> रामदेव सिंह (पूर्व
            सरपंच), ग्राम पोस्ट बिहटा, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> सुन्दरलाल पटेल, ग्राम
            बरही, पोस्ट मैहर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> प्रताप सिंह, ग्राम
            रघुनाथपुर, पोस्ट जर्नादनपुर, जिला-सतना (म.प्र.)
          </h6>

          <h5 className="color-orange-red fw-bold">
            सन् 1995 में श्री महेन्द्र सिंह, नेता जी के स्मृतिशेष होने पर वर्ष
            1996 की कार्यकारणी-
          </h5>

          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> अध्यक्ष पद रिक्त
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> श्री छोटेलाल सिंह,
            उपाध्यक्ष एवं कार्यकारी अध्यक्ष- अन्य सभी पदाधिकारी एवं कार्यकारणी
            सदस्य यथावत ।
          </h6>

          <h5 className="color-orange-red fw-bold">
            सन् 2000 से 2019 तक की कार्यकारणी
          </h5>

          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> चौधरी महेन्द्र सिंह,
            अध्यक्ष, ग्राम रघुनाथपुर, पोस्ट जर्नादनपुर, जिला-सतना (म.प्र.)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> श्री छोटेलाल सिंह,
            उपाध्यक्ष
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> प्रो. आर.डी. सिंह, सचिव
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> रामनिरंजन सिंह,
            कोषाध्यक्ष
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> शारदा सिंह, सहसचिव
          </h6>

          <h5 className="color-orange-red fw-bold">
            स्मृतिशेष हो चुके संस्थापक सदस्य
          </h5>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> श्री बैजनाथ प्रसाद ( दादू
            पटेल)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> श्री लालजी भाई पटेल (
            पूर्व विधायक)
          </h6>
          <h6 className="color-grey fw-bold">
            <img src={right} alt="" className="w-1" /> श्री रामाधार सिंह (मुंशी
            जी )
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> श्री रामदेव सिंह
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> श्री गोविन्द सिंह
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> श्री सुन्दरलाल पटेल के
            स्मृतिशेष होने पर
          </h6>
          <h6 className="color-grey fw-bold">
            {" "}
            <img src={right} alt="" className="w-1" /> श्री गोविन्द सिंह
          </h6>

          <h5 className="color-orange-red fw-bold">
            अन्य आजीवन सदस्य ट्रस्ट मण्डल में सम्मिलित किये गए।
          </h5>
        </div>

        <div className="w-75 rounded-3 d-flex flex-wrap gap-3 h-fit">
          {state === 1 &&
            foundeMember.map((elem) => {
              return (
                <>
                  <MemberCardNoSlider item={elem} />
                </>
              );
            })}
          {state === 2 &&
            currentMember.map((elem) => {
              return (
                <>
                  <MemberCardNoSlider item={elem} />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Member;
